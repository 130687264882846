/**
 * Load Styles
 */
import '../scss/main.scss';

/**
 * Load jQuery
 */
import $ from 'jquery';


/**
 * Load Bootstrap
 */
import {initBootstrap} from "./bootstrap.js";

// import "bootstrap";


/**
 * Init Bootstraß
 */

initBootstrap({
    tooltip: true,
    popover: true,
    toasts: true,
});

/**
 * get VAR from Bootstraß
 */
import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;


/**
 * NPO Scripts
 *
 * @author Stephan Heller [heller@daik.de]
 * @version 1
 * @since 2023-05-25
 *
 */

try {

    /**
     * global NPO Vars
     * @type {*|jQuery|HTMLElement}
     */

    var $navButon = $('.navbar-toggler');
    var $navBar = $('#navbar');
    var $offerFilter = $('.offer-filter');
    var $moreFilter = $('.filter-row + .filter-row .filter-block');
    var latestOpenedFilterCheckbox = '';
    var latestKeyCode = '';
    var $resetLink = $('.reset-filter');
    var $sucheResetLink = $('.reset-filter-suche');
    var loadmoreState = true;
    var documentUrl = window.location.href;
    var winWidth = $('body').width();

    var markers = null;
    var map = null;

    // console.log('window.location', window.location)

    var isDevServer = window.location.origin == 'http://localhost:9090' || window.location.href.indexOf('daik.de') > 0 ? 1 : 0;
    var imgBaseUrl = isDevServer ? 'https://devfhi.formulardb.de/' : '';
    var limitOfResults = 20;

    var $toggleMap = $('#toggle-map');
    var $toggleSwitch = $('#more-filter');

    var $searchResultMap = $('#search-result-map');

    var geoJsonLayer = null;
    var isMapVisible = false;

    var navbarState = false;

  var mapLoad = function(data) {
    setTimeout(function () {
      if (map) {
        if (markers) {
          markers.clearLayers();
        }

        if (data['map'].length > 0) {

          geoJsonLayer = ym.geoJson(data['map'], {
            onEachFeature: function (feature, layer) {
              layer.bindPopup(feature.properties.objekt);
            }
          });

          let angeboteMarkers = new L.MarkerClusterGroup({
            iconCreateFunction: function (cluster) {
              return new L.DivIcon({
                html: '<div ' +
                  'style="background-color: darkgrey !important;' +
                  'height: 35px;' +
                  'width: 35px;' +
                  'border-radius: 50%;' +
                  'display: inline-block;"><b>' + cluster.getChildCount() + '</b></div>'
              });
            },
            polygonOptions: {stroke: false, color: 'transparent'}
          });

          var angeboteIcon = L.icon({
            iconUrl: '/images/angebote-pin.png',
            popupAnchor: [0, -55],
            iconAnchor: [32, 60]
          });

          markers.addLayer(geoJsonLayer);
          map.addLayer(markers);
          map.fitBounds(markers.getBounds());
        }
      }
    }, 4000);
  }

    if (localStorage.getItem('isMapVisible') == 'true') {
      $toggleSwitch.prop('checked', true);
      $toggleMap.prop('checked', true);

      isMapVisible = true;

      $searchResultMap.css("left", "0%");
      $searchResultMap.css("position", "sticky");
    } else {
      $toggleSwitch.prop('checked', false);
      $toggleMap.prop('checked', false);

      isMapVisible = false;
      $searchResultMap.css("left", "-100%");
      $searchResultMap.css("position", "absolute");
    }

    var $dataListAutoComplete = $('#auto-complete-search');
    var $dataListPlz = $('#auto-complete-plz');

  setTimeout(function () {
    setTimeout(function () {
      initMap();
      stateSearchfield();
    }, 1500);
  }, 2000);

  function stateSearchfield() {
    var suchfeld = $('#search').val();
    var sucheResetLink = $('.reset-filter-suche');
    if (suchfeld.length > 0) {
      sucheResetLink.addClass('active')
    } else {
      sucheResetLink.removeClass('active')
    }
  }

  function initMap() {
    if (typeof ym !== "undefined") {
      ym.ready(function (modules) {
        map = ym.map("search-result-map", {
          trackResize: true,
          attributionControl: true,
          zoomControl: true,
          fullscreenControl: true,
          fullscreenControlOptions: {
            position: 'topleft'
          },
          zoom: 25,
        });

        markers = ym.markerClusterGroup({
          disableClusteringAtZoom: 19
        });
      });
    }
  }

    $toggleMap.on('click', function (e) {
      localStorage.setItem('isMapVisible', !isMapVisible);

      e.preventDefault();

        if (!isMapVisible) {
            isMapVisible = true;

            $toggleSwitch.prop('checked', true);

            $searchResultMap.css("left", "0%");
            $searchResultMap.css("position", "sticky");

            var postData = $('#filter-form').serializeArray();

            if (markers) {
              markers.clearLayers();
            }

            var ajax = $.ajax({
                url: '/fhiangebot/searchredesign',
                method: 'GET',
                async: true,
                data: postData,
                cache: false
            })
                .done(function (jqXHR, textStatus) {
                    var data = JSON.parse(jqXHR);

                    if (map) {
                      if (data['map'].length > 0) {
                        geoJsonLayer = ym.geoJson(data['map'], {
                          onEachFeature: function (feature, layer) {
                            layer.bindPopup(feature.properties.objekt);
                          }
                        });
                        let angeboteMarkers = new L.MarkerClusterGroup({
                          iconCreateFunction: function (cluster) {
                            return new L.DivIcon({
                              html: '<div ' +
                                'style="background-color: darkgrey !important;' +
                                'height: 35px;' +
                                'width: 35px;' +
                                'border-radius: 50%;' +
                                'display: inline-block;"><b>' + cluster.getChildCount() + '</b></div>'
                            });
                          },
                          polygonOptions: {stroke: false, color: 'transparent'}
                        });

                        var angeboteIcon = L.icon({
                          iconUrl: '/images/angebote-pin.png',
                          popupAnchor: [0, -55],
                          iconAnchor: [32, 60]
                        });

                        markers.addLayer(geoJsonLayer);
                        map.addLayer(markers);
                        map.invalidateSize();
                        map.fitBounds(markers.getBounds());
                      }
                    }
                });
        } else {
            isMapVisible = false;
            $searchResultMap.css("left", "-100%");
            $searchResultMap.css("position", "absolute");

            $toggleSwitch.prop('checked', false);
        }
    });

    /**
     * INIT wenn Dokuemnt geladen
     */
    $(document).ready(function () {

        /**
         * Init Carroussel auf Startseite
         */
        if ($('.carousel').length > 0) {
            var myCarousel = document.querySelector('.carousel');
            var userStop = true;
            if (typeof myCarousel != null) {
                var carousel = new window.bootstrap.Carousel(myCarousel, {
                    interval: 5000,
                    wrap: true
                });
                carousel.cycle();
                $('#play').on('click', function () {
                    if (userStop == true) {
                        carousel.pause();
                        $(this).addClass('paused');
                        $(this).attr('aria-label', 'Automatische Bewegung fortsetzen ').find('span').text('Automatische Bewegung fortsetzen');
                        userStop = false;
                    } else {
                        carousel.cycle();
                        $(this).removeClass('paused');
                        $(this).attr('aria-label', 'Automatische Bewegung anhalten').find('span').text('Automatische Bewegung anhalten');
                        userStop = true;
                    }
                });
            }
        }

        /**
         * mobile Navigation öffnen
         */
        $navButon.on('click', function () {
            toggleNav();
        });

        /**
         * Suche auslösen
         */
        $('#search-form').on('click', function () {
            $('#search').focus();
        })

        /*$('#search').on('focus', function () {
            toggleNav('close');
        });*/

        if ($('.content-header').length > 0 && $('#label-alter').length > 0) {
            var agePos = $('#label-alter .selected-value').position();
            $('#age ~ ul').css('left', Math.round(agePos['left']));
        }

        /**
         * BF-Features. Besstimmte Elemente werden auf ESC geschlossen
         */
        $(document).keydown(function (e) {
            // ESCAPE key pressed
            if (e.keyCode == 27) {
                resetTopicSelet();
                toggleNav('close');
                var $formFields = $('.offer-filter');
                $formFields.prop('checked', false);
            }
            latestKeyCode = e.keyCode;
        });

        /**
         * Orte-Auswahl im Footer
         * nach der Auswahl wird der Link gesetzt - damit dieser Manuell ausgelöst werden kann (kein automatisches Auto-Submit weg. BF)
         */
        $('#orte').on('change', function (event) {
            $(event.target).next('a').show().attr('href', $(event.target).val());
        });

        /**
         * Orte Auwahl im Footer zurück gesetzt
         */
        $('#ort-selection a').on('click', function (event) {
            $(event.target).hide();
            $('#orte').val('NULL');
        });

        /**
         * Handling der Filter im HEad
         */
        $('.content-header ul li:last-child a').on('blur', function () {
            resetTopicSelet();
        });

        $('body').on('click', function (event) {
            if ($(event.target).parents('.topic-filter').length > 0) {
                return;
            }
            resetTopicSelet();
        });



        /**
         * verschiedenen Reet-Close-Filter Funktionen des Angebots-Filter
         */
        $('.offer-filter, .reset-filter, .close-filter').on('click', function (event) {
            if (event.target.tagName == 'A') {
                if (event.target.className.indexOf('reset-filter') < 0) {
                    event.preventDefault();
                }
            } else {
                latestOpenedFilterCheckbox = event.target.id;
            }
            if (event.target.className.indexOf('reset-filter') > -1) {
              const urlParams = new URLSearchParams(window.location.search);
              let path = '';
              if (urlParams.get('age') !== 'undefined' || urlParams.get('topic') !== 'undefined') {
                path = window.location.origin + window.location.pathname + '?topic=undefined&age=undefined';
                if (urlParams.get('newlayoutpreview') === '1') {
                  path += '&newlayoutpreview=1'
                }
                if (urlParams.get('q')) {
                  path += '&q=' + urlParams.get('q');
                }
              } else {
                path = location.href;
              }

              document.location.href = path;
            } else if (event.target.className == 'close-filter') {
                var $parentElem = $(event.target).parents('.single-filter');
                var $checkboxAndRadios = getCheckboxesAndRadiosOfParentElem($parentElem, '198');
                var $textDateSelectsFields = getTextDateSearchSelectsOfParentElem($parentElem);
                $textDateSelectsFields.val('');
                if ($checkboxAndRadios.length > 0) {
                    $checkboxAndRadios.each(function (index, box) {
                        if ($(box).hasClass('keep')) {
                            $(box).prop('checked', true);
                            $(box).attr('checked', 'checked');
                        } else {
                            $(box).prop('checked', false);
                            $(box).removeAttr('checked');
                        }
                    })
                }
                if ($textDateSelectsFields.length > 0) {
                    $textDateSelectsFields.each(function (index, box) {
                        if ($(box).hasClass('keep')) {
                            // alert($(box).data('latest-val'));
                            $(box).val($(box).data('latest-val'));
                        } else {
                            $(box).val();
                        }
                    })
                }
                displayNumberOfFilter(event.target, 'AAA');
                $('#' + latestOpenedFilterCheckbox).focus();
            }
            dealOfferFilter(event.target);
        });

        /**
         * Weitere Angebots-Eergebnisse laden
         */
        $('.load-more').on('click', function (event) {
            event.preventDefault();
            event.stopPropagation();
            var latestStart = parseInt(document.getElementById('start').value, 10);
            $('#start').val(latestStart + limitOfResults);
            var postData = $('#filter-form').serializeArray();
            loadAjaxData(loadTask, postData, 'more')
        });

        /**
         * Nach Klick der Checkboxen wird der übergeordnete Filter-Kat mit der Anzahl der Auswahlen ausgelöst.
         */
        $('.form-fields input[type=checkbox], .form-fields input[type=radio]').on('click', function (event) {
          displayNumberOfFilter(event.target, 'BBB');
        });
        $('.form-fields input[type=text]').on('keyup', function (event) {
            displayNumberOfFilter(event.target, 'CCC');
        });
        $('.form-fields input[type=date]').on('change', function (event) {
            displayNumberOfFilter(event.target, 'DDD');
        });
        $('.form-fields input[type=search]').on('keyup', function (event) {
            displayNumberOfFilter(event.target, 'ZZZ');
        });
        $('.form-fields select').on('change', function (event) {
            displayNumberOfFilter(event.target, 'EEE');
        });

        /**
         * Auswahl in einem Filterblock wird angewendet
         */
        $('.single-filter .confirm-filter').on('click', function (event) {
            event.preventDefault();
            event.stopPropagation();
            // console.log('RESET');
            $('#start').val(0);

            var inputId = $(event.target).parents('.filter-block').find('> input').attr('id');
            if (isDevServer) {
                console.log('inputId: ' + inputId);
            }
            var $parentElem = $('#' + inputId + ' ~ .single-filter');
            var $checkboxAndRadios = getCheckboxesAndRadiosOfParentElem($parentElem, '269');
            var $textDateSelectsFields = getTextDateSearchSelectsOfParentElem($parentElem);
            if ($checkboxAndRadios.length > 0) {
                $checkboxAndRadios.each(function (index, box) {
                    if ($(box).prop('checked')) {
                        $(box).addClass('keep');
                    } else {
                        $(box).removeClass('keep');
                    }
                })
            }
            if ($textDateSelectsFields.length > 0) {
                $textDateSelectsFields.each(function (index, box) {
                    if (isDevServer) {
                        // console.log('tag', box);
                    }
                    if ($(box).val() != '') {
                        $(box).addClass('keep').attr('data-latest-val', $(box).val());
                    } else {
                        $(box).removeClass('keep').removeAttr('data-latest-val');
                    }
                })
            }

            /**
             * Tast für AJJAX wird ermittelt,
             * Forulardaten ermittelt und als Array zusammen gefürht
             * AJAX-Call ausgelöst
             */
            var loadTask = $loadElem.data('load-task');
            var getData = $(event.target).parents('form').serialize();
            loadAjaxData(loadTask, getData);

            dealOfferFilter(event.target);

            var $relCheckbox = $('#' + inputId);

            $relCheckbox.attr('aria-expanded', false);
            $relCheckbox.removeAttr('checked');
            $relCheckbox.prop('checked', false);

            setTimeout(function () {

                /**
                 * KeyCode  13: Return
                 *          32: Space
                 */

                if (latestKeyCode != 13) {
                    $relCheckbox.focus();
                    if (isDevServer) {
                        console.log($relCheckbox, 183)
                    }
                } else {
                    if (isDevServer) {
                        console.log('CLICK SUBMIT - Fotos auf letzte CHEBOX')
                    }
                    $('#' + latestOpenedFilterCheckbox).focus();
                }
            }, 100);
        });


        /**
         * AUSKOMMETNEIRT - initiales setzen der Filter bei Seitenrelaod
         */
        if (false) {
            var $firstCheckboxOfSingleFilter = $('.single-filter .cb-1');
            if ($firstCheckboxOfSingleFilter.length > 0) {
                $.each($firstCheckboxOfSingleFilter, function () {
                    // if (isDevServer){console.log(this, 'this');}
                    displayNumberOfFilter(this, 'FFF');
                })
            }
        }

        /**
         * AUSKOMMENTIERT - erst Kalender-Umsetzung
         */
        if (false) {
            $('#date-selection a').on('click', function (event) {
                event.preventDefault();
                dealCalendarSelection(event.target);

            });
        }

      let timer;

        /**
         * Auto-Suggest Suche - nach 3 Zeichen Eingabe triggern
         */
        /*$('#search').on('keyup', function (event) {
          clearTimeout(timer);

          timer = setTimeout(function() { //then give it a second to see if the user is finished
            var str = event.key;
            if (str.length === 1 && str.match(/\S| /)) {
              event.preventDefault();
              var searchTerm = $(event.target).val();
              if (searchTerm.length > 2) {
                var postData = $('#filter-form').serializeArray();
                var q = { 'q': searchTerm };

                var mergedObj = { ...q, ...postData };

                loadSearchResults(mergedObj);
              }
            } else {
              $dataListAutoComplete.html('');
              //$dataListAutoComplete.hide();
            }
          }, 1000);
        });*/

        /**
         * Autosuggest - nach Eingabe von 3 Zeichen triggern
         */
        $('#wo_plz').on('keyup', function (event) {
            var str = event.key;

            if (str.length === 1 && str.match(/\S| /)) {
                event.preventDefault();

                $('#stadtteil').prop('disabled', true);
                $('#stadtteil').val('');

                var searchTerm = $(event.target).val();
                if (searchTerm.length > 2) {
                    loadPLZResults(searchTerm);
                } else {
                  $dataListPlz.html('');
                }
            } else {
                $('#stadtteil').prop('disabled', false);
                if (isDevServer) {
                    console.log('kein Buchstabe', str)
                }
            }

            if ($(event.target).val().length === 5) {
              $('#umkreis').prop('disabled', false);
            } else {
              $('#umkreis').prop('disabled', true);
            }
        });

        $('#stadtteil').change(function (event) {
          console.log($( "#stadtteil option:selected" ).text());
            if($( "#stadtteil option:selected" ).text() === 'Gesamtgebiet') {
                $('#wo_plz').prop('disabled', false);
            } else {
                $('#wo_plz').prop('disabled', true);
                $('#wo_plz').val('');
            }
        });

        /**
         * Klick auf die Filter-Kats (Wo, Für wen, usw.
         * Für Screenreader werden Attribute gesetzt, dass die Filterblöcke geöffnet sind
         */
        $('.topic-filter [type=checkbox], .content-col .filter-block > [type=checkbox]').on('change', function (event) {
            var $aktCheckbox = $(event.target);

            $('.topic-filter [type=checkbox]').not($aktCheckbox).prop('checked', false)

            if (isDevServer) {
                console.log('$aktCheckbox', $aktCheckbox)
            }
            if ($($aktCheckbox).prop('checked')) {
                $aktCheckbox.attr('aria-expanded', true);
            } else {
                $aktCheckbox.attr('aria-expanded', false);
            }
        });


        /**
         * Steuerung der Select-Elemente im Seitenkopft
         */
        $('.pseudo-select li a').on('click', function (event) {
            var $aktLink = $(event.target);
            if ($aktLink.attr('href') == '#' || $aktLink.attr('href').indexOf('#') > -1) {
                event.preventDefault();
            } else {
                return;
            }
            var inputId = $('#' + $aktLink.parents('.pseudo-select').attr('aria-labelledby')).attr('for');
            var addText = '';
            var $relCheckbox = $('#' + inputId);
            if (inputId == 'topics') {
                addText = '<span class="sr-only">ausgewählt:</span> ';
            }

            $('#' + $aktLink.parents('.pseudo-select').attr('aria-labelledby') + ' .selected-value').html(addText + $aktLink.text());

            $relCheckbox.attr('aria-expanded', false);
            $relCheckbox.removeAttr('checked');
            $relCheckbox.prop('checked', false);

            setTimeout(function () {
                $relCheckbox.focus();
                if (isDevServer) {
                    console.log($relCheckbox, 264)
                }
            }, 100);
        });


        /**
         * URL zum Kopieren wird  gesetzt
         */
        var filterLinkInput = $('#share-link');
        var copyUrl = '';

        $('.share > a').on('click', function (event) {
            event.preventDefault();
            event.stopPropagation();
            $('.url').text('');
            if (filterLinkInput.length > 0) {
                $('.url').text(filterLinkInput.val());
            } else {
                $('.url').text(documentUrl);

            }
            $(this).parent('.share').find('.copy-block').toggleClass('open')
        });

        /**
         * Links werden in Zwischenablage kopiert
         */
        $('.copy-link').on('click', function (event) {
            event.preventDefault();
            event.stopPropagation();

            // Copy the text inside the text field
            if (filterLinkInput.length > 0) {
                copyUrl = $('.url').text();
            } else {
                copyUrl = documentUrl;
            }
            navigator.clipboard.writeText(copyUrl).then(() => {
                console.log("successfully copied");
            })
                .catch(() => {
                    console.log("something went wrong");
                });
            alert('Link zu dieser Seite wurde in die Zwischenablage kopiert.');

        });


        /**
         * Tast für AJAX wird ermittelt - und je nach Job weitere Funktioen aufgerufen
         */
        var $loadElem = $('#load-task');
        var selectedSubInputs = 0;
        if ($loadElem.length) {
            var loadTask = $loadElem.data('load-task');

            switch (loadTask) {
              case 'load-offer':
                    setFilterFromUrl();
                    selectedSubInputs = $('.filter-col input[name*=filter]:checked').not('#box-0').length;
                    if (selectedSubInputs == 0) {
                        $('#box-0').attr('checked', 'checked').prop('checked', true);
                    } else {
                        $('#box-0').removeAttr('checked').prop('checked', false);
                    }
                    showLoader();
                    var postData = $('#filter-form').serializeArray();

                    let q = {};
                    q['name'] = 'q';
                    q['value'] = $('#search').val();

                    postData.push(q);

                    console.log(postData);

                    loadAjaxData(loadTask, postData, 'init');
                    $('.filter-col input[name*=filter]').on('change', function (event) {
                        /**
                         * Alle an/ Abwählen
                         */
                        var inputId = $(event.target).attr('id');

                        console.log('Filter changed');
                        $('#start').val(0);
                        loadmoreState = true;

                        if (inputId == 'box-0') {
                            $('.filter-col input[name*=filter]:checked').not('#box-0').removeAttr('checked').prop('checked', false);
                        }
                        selectedSubInputs = $('.filter-col input[name*=filter]:checked').not('#box-0').length;
                        if (selectedSubInputs == 0) {
                            $('#box-0').attr('checked', 'checked').prop('checked', true);
                        } else {
                            $('#box-0').removeAttr('checked').prop('checked', false);
                        }

                        var postData = $('#filter-form').serializeArray();
                        loadAjaxData(loadTask, postData)
                    });
                    break;
                case 'load-provider':
                    showLoader();
                    var postData = $('#filter-form').serializeArray();
                    loadAjaxData(loadTask, postData, 'init');
                    $('input[name=filter]').on('change', function (event) {

                        $('#start').val(0);
                        loadmoreState = true;

                        var postData = $(event.target).parents('form').serialize();
                        loadAjaxData(loadTask, postData);
                    });
                    break;
                default:
                    if (isDevServer) {
                        console.log('no task found: ' + loadTask);
                    }
            }
        }


        /**
         *  "Nach Oben" Link wird ein oder ausgeblendet bzw. die Funktiion dazu getriggert
         */

        var $toTop = $('#to-top');
        if ($toTop.length > 0) {
            var winH = $(window).height();
            if (isDevServer) {
                console.log(footerPos, 'footerPos')
            }
            var footerH = $('footer').height();

            if (winWidth >= 1240) {
                $(window).scroll(function () {
                    showHideToTop($(window).scrollTop(), winH, footerH, $toTop);
                });
            }
        }

    });


    /**
     * mobiel Navigation wird bedient
     * @param param optional "close" Flag
     */
    var toggleNav = function (param) {
        if ($($navButon).is(":hidden")) {
            return;
        }
        if (typeof $navButon == "undefined") {
            return;
        }
        if (param === false) {
            $navButon.addClass('collapsed');
            $navBar.removeClass('open');
            $navBar.attr('z-index', -5);
        } else {
            $navBar.show();
            $navButon.toggleClass('collapsed');
            $navBar.toggleClass('open');
            $navBar.attr('z-index', 5);
        }
        if ($navBar.hasClass('open')) {
            $navBar.animate({left: '0'}, 200, "linear");
            $navBar.attr('aria-expanded', true);
            $navButon.attr('aria-expanded', true);
            $navButon.attr('aria-label', 'Navigation schließen');
            $navBar.attr('z-index', 5);
        } else {
            $navBar.animate({left: '110%'}, 200, "linear");
            $navBar.attr('aria-expanded', false);
            $navButon.attr('aria-expanded', false);
            $navButon.attr('aria-label', 'Navigation öffnen');
            setTimeout(function () {
                $navBar.hide();
                $navBar.attr('z-index', -5);
            }, 300);
        }
    };

    /**
     * Auswahl im Header wird zurück gesetzt
     */
    var resetTopicSelet = function () {
        $('.content-header input').prop('checked', false);
        $('.content-header input').removeAttr('checked');
    };

    /**
     * wenn URL mit Parametern übergeben werden,
     * wird das Filter-Fomualar automatisch ausgefüllt
     * Funktion ermittelt Feld-Namen und Input bzw. Form-Elemente automatisch
     * und setzt die WErten davon abhängig automatisch
     */
    var setFilterFromUrl = function () {
        var recentUrl = decodeURI(window.location.href);
        if (recentUrl.indexOf('?') > 0) {
            var aUrlParams = recentUrl.split('?');
            var aUrlPairs = aUrlParams[1].split('&');
            var aSingleVals = [];
            var recentFormField = {};
            var formType = '';
            $.each(aUrlPairs, function (index, item) {
                if (isDevServer) {
                    // console.log(index, item)
                }
                aSingleVals = item.split('=');
                switch (aSingleVals[0]) {
                    case 'load-task':
                    case '_':
                        // NIX
                        break;
                    default:
                        recentFormField = $('[name=' + aSingleVals[0] + ']');
                        formType = recentFormField.attr('type');
                        if (typeof formType != "undefined") {
                            switch (formType) {
                                case 'checkbox':
                                    if (!recentFormField.hasClass('offer-filter')) {
                                        recentFormField.attr('checked', 'checked').prop('checked', true);
                                    }
                                    break;
                                case 'radio':
                                    recentFormField = $('[value=' + aSingleVals[1] + ']');
                                    recentFormField.attr('checked', 'checked').prop('checked', true);
                                    break;
                                case 'date':
                                case 'search':
                                case 'hidden':
                                    recentFormField.attr('value', aSingleVals[1]);
                                    break;
                                default:
                                    if (isDevServer) {
                                        console.log('++++++++++++++ AUSARBIETEN ++++++++++++++')
                                        console.log('aSingleVals ', aSingleVals)
                                        console.log('recentFormField ', recentFormField)
                                        console.log('formType ', formType)
                                    }
                            }
                        } else {
                            if (recentFormField.prop("tagName") == 'SELECT') {
                                if (aSingleVals[1]) {
                                    recentFormField.val(aSingleVals[1]);
                                }
                            }
                        }

                }
            });
        }

        setTimeout(function () {
            var $firstCheckboxOfSingleFilter = $('.single-filter .cb-1');
            if ($firstCheckboxOfSingleFilter.length > 0) {
                $.each($firstCheckboxOfSingleFilter, function () {
                    displayNumberOfFilter(this, 'FFF');
                })
            }

            $('.offer-filter').removeAttr('checked').prop('checked', false);

        }, 250);

    };


    /**
     * Ermittelt die Radio-und Checkboxen in einem Filterblock
     * @param $parentElem selector des Filter-Blocks
     * @param call DEBUG- Aufruf aus....
     * @returns {*}
     */
    var getCheckboxesAndRadiosOfParentElem = function ($parentElem, call) {
        var $checkboxAndRadios = $parentElem.find('[type=checkbox], [type=radio]');
        if (isDevServer) {
            console.log('$checkboxAndRadios: ' + call, $checkboxAndRadios);
        }
        return $checkboxAndRadios;
    };

    /**
     * ermittelt Formularelemente vom Type Text, Seacht und Date in einem Block
     * @param $parentElem Selector Filter-Block
     * @returns {*}
     */
    var getTextDateSearchSelectsOfParentElem = function ($parentElem) {
        var $textDateSelectsFields = $parentElem.find('[type=text],[type=date],[type=search],select');
        if (isDevServer) {
            console.log('$textDateSelectsFields', $textDateSelectsFields);
        }
        return $textDateSelectsFields;
    };

    /**
     * OBSOLOETE - Mehr filter gibt es nciht mehr...
     */
    var dealOfferFilter = function (elem) {
        $offerFilter.not($(elem)).removeAttr('checked').prop('checked', false);
        if ($(elem).parent('div').hasClass('more-filter')) {
            dealMoreFilter();
        }
    };


    /**
     * Zeigt an, wie viel Felder in einem Filter-Block ausgewählt sind (Anzahl rotes Bubble)
     * @param elem
     * @param callfrom
     */
    var displayNumberOfFilter = function (elem, callfrom) {
        if (isDevServer) {
            // console.log(elem, 'elem - type: ' + (typeof elem))
            console.log('callfrom', callfrom);
        }

        var selectedCheckboxes = $(elem).parents('.single-filter').find('[type=checkbox]:checked, [type=radio]:checked').length;
        var selectedInputs = $(elem).parents('.single-filter').find('[type=text], [type=search]');
        var selectedDates = $(elem).parents('.single-filter').find(' [type=date]');
        var selectedSelects = $(elem).parents('.single-filter').find('select');

        var countInputsWithContent = checkTextFieldContent(selectedInputs);
        countInputsWithContent += checkTextFieldContent(selectedDates);

        countInputsWithContent += checkSelectFieldSelection(selectedSelects);

        var showSelection = selectedCheckboxes + countInputsWithContent;

        if (selectedDates.length) {
            var datumVon = $('#day-start').val();
            var datumBis = $('#day-end').val();

            if (datumVon != '') {
                showSelection = convertDateToDe(datumVon);
            }
            if (datumVon != '' && datumBis != '') {
                showSelection += ' - ';
            }
            if (datumBis != '') {
                showSelection += convertDateToDe(datumBis);
            }
        }


        $(elem).parents('.filter-block').find('.counter').html(showSelection + ' <span class="sr-only">Optionen ausgewählt</span>');

        /**
         * Wenn eine Formularfeld innerhalb eines Filterblocks ausgewählt ist,
         * wird der übergeordnet Kat-Filter als "aktiv" angezeigt.
         */

        if (selectedCheckboxes + countInputsWithContent > 0) {
            $(elem).parents('.filter-block').find('> label').addClass('active');
        } else {
            $(elem).parents('.filter-block').find('> label').removeClass('active');

        }
        showHideResetLink();

    };

    /**
     * Prüft Eingaben mit Inhalt in Text-Such-Date-Feldern
     * @param selectedInputs
     * @returns {number}
     */
    var checkTextFieldContent = function (selectedInputs) {
        var countInputsWithContent = 0;
        if (selectedInputs.length > 0) {
            selectedInputs.each(function (index, tag) {
                if ($(tag).val() != '') {
                    countInputsWithContent += 1;
                }
            })
        }
        if (isDevServer) {
            // console.log('countInputsWithContent: ' + countInputsWithContent, selectedInputs);
        }
        return countInputsWithContent;
    };

    /**
     * PRüft ausgewählte Checkboxen und Radios
     * @param selectedSelects
     * @returns {number}
     */
    var checkSelectFieldSelection = function (selectedSelects) {
        var countSelectedSelects = 0;
        if (selectedSelects.length > 0) {
            selectedSelects.each(function (index, tag) {
                if ($(tag).val() != '') {
                    countSelectedSelects += 1;
                }
            })
        }
        return countSelectedSelects;

    };

    /**
     * Zeigt den Mehr-Laden-Button an oder aus
     */
    var dealMoreFilter = function () {
        if ($moreFilter.is(':visible')) {
            $moreFilter.hide();
        } else {
            $moreFilter.show();

        }
    };

    /**
     * Zeit oder blendet den Reset-Filter-Link aus
     */
    var showHideResetLink = function () {
        var allSelectedCheckboxes = $('.single-filter').find('[type=checkbox]:checked, [type=radio]:checked').length;
        var selectedInputs = $('.single-filter').find('[type=text] ,[type=date],[type=search]');
        var selectedSelects = $('.single-filter').find('select');
        var countInputsWithContent = checkTextFieldContent(selectedInputs);
        countInputsWithContent += checkSelectFieldSelection(selectedSelects);

        if (allSelectedCheckboxes + countInputsWithContent > 0) {
            $resetLink.addClass('active')
        } else {
            $resetLink.removeClass('active')
        }
    };

  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
    return false;
  };

    /**
     * Abhöngig von der "Aufgabe" werden Daten per AJAX aus dem Backend
     * ermittelt und jenach dem an die Darstellungsfunktion übergeben
     * @param task
     * @param postData
     * @param type
     */
    var loadAjaxData = function (task, postData, type) {

        var jsonUrl = '';
        switch (task) {
            case 'load-offer':
              showLoader();
              jsonUrl = '/fhiangebot/searchredesign';
              break;
            case 'load-provider':
                showLoader();
                jsonUrl = '/fhianbieter/searchredesign';
                break;
            case 'load-plz':
                jsonUrl = '/fhiangebot/getplz';
                if (isDevServer) {
                    jsonUrl = 'https://npo:npo2023@npo.daik.de/json/plz.json';
                }
                break;
            case 'load-search':
                jsonUrl = '/fhiangebot/searchredesign?limit=10';
                if (isDevServer) {
                    jsonUrl = 'https://npo:npo2023@npo.daik.de/json/search.json';
                }
                break;
            default:
                if (isDevServer) {
                    console.log('no task found: ' + task);
                }
        }

        var jqxhr = $.ajax({
            url: jsonUrl,
            method: 'GET',
            async: false,
            data: postData,
            cache: false
        })
            .done(function (jqXHR, textStatus) {

                var urlParts = this.url.split('?');
                $('#share-link').attr('value', documentUrl + '?' + urlParts [1]);

                var data = JSON.parse(jqXHR);

                $.each(data.kategories, function (index, item) {
                  let boxIndex = $('#box-'+index+'-number');
                  boxIndex.html('('+item.anzahl_angebote+')');
                });

                switch (task) {
                    case 'load-plz':
                        loadSearchResults(data.data, task, $('#auto-complete-plz'), '#wo_plz', '#combobox-plz', '#auto-complete-plz');
                        break;
                  case 'load-search':
                        //loadSearchResults(data.data, task, $('#auto-complete-search'), '#search', '#combobox-search', '#auto-complete-search');
                        break;
                    default:
                        displayJsonData(data.data, task, type);
                        setNumberOfOffer(data);
                }

                mapLoad(data);
            })
            .fail(function (jqXHR, textStatus) {
                if (isDevServer) {
                    console.log(jqXHR, 'jqXHR')
                }
                if (isDevServer) {
                    console.log("request failed: " + textStatus);
                }
                hideLoader();
                displayErrorMessage('Daten konnten nicht geladen werden.');
            })
            .always(function () {
                // alert( "complete" );
            });


    };

    /**
     * Anbieter bzw. Angebots-Daten werden ins HTML übertragen
     * verschiedene Fehlerfälle im JSON werden abgefangen
     *
     * @param data
     * @param loadTask
     * @param type
     */
    var displayJsonData = function (data, loadTask, type) {

        var angebotLink = '';
        var freeOffer = '';
        var offerForm = '';
        var offerFee = '';
        var offerFeeComment = '';
        var offerDesc = '';
        var providerDesc = '';


        switch (loadTask) {
            case 'load-offer':
                var $resultBox = $('#search-result');
                break;
            case 'load-provider':
                var $resultBox = $('#result-box');
                break;
            default:
                if (isDevServer) {
                    console.log('no task found: ' + loadTask);
                }
        }

        $resultBox.html();

        var displayDone = false;
        var ageLi = '';
        var metaH2 = '';
        if (type != 'more') {
            $resultBox.text('');
        }


        if (typeof data == 'object' && data.length > 0) {
            if (data.length < 20) {
              $('.load-more').hide();
              loadmoreState = false;
            }

            $('#result-box .error').remove();
            $.each(data, function (index, item) {
                if (isDevServer && !displayDone) {
                    displayDone = true;
                    // return;
                }
                try {
                    switch (loadTask) {
                        case 'load-offer':
                            metaH2 = '';
                            if (item.anbieter_name && item.anbieter_name != 'null') {
                                metaH2 = '<span class="meta">' + item.anbieter_name + '</span>';
                            }
                            if (item.beschreibung != null) {
                              let shortText = jQuery.trim(item.beschreibung).substring(0, 500).split(" ").slice(0, -1).join(" ") + " [...]";
                              let longText = jQuery.trim(item.beschreibung).substring(497).split(" ").slice(0, -1).join(" ");
                                if (item.beschreibung.indexOf('<p') > -1) {
                                    /*if (item.beschreibung.length > 500) {
                                      offerDesc = '<p>' + shortText + '<div id="more-'+item.id+'" style="max-width: 100%; display: none"><p>' + longText + '</p></div></p><button id="link-'+item.id+'" onclick="showMore('+ item.id + ')">Mehr anzeigen</button><br><br>';
                                      //<div id="more-'+item.id+'" style="display: none">' + longText + '</div><a id="link-'+item.id+'" href="javascript:showMore('+ item.id + ')">Klick mich</a>
                                    } else {
                                      offerDesc = item.beschreibung;
                                    }*/

                                  offerDesc = shortText;
                                } else {
                                  /*if (item.beschreibung.length > 500) {
                                    offerDesc = '<p>' + shortText + '<div id="more-'+item.id+'" style="max-width: 100%; display: none"><p>' + longText + '</p></div></p><button id="link-'+item.id+'" onclick="showMore('+ item.id + ')">Mehr anzeigen</button><br><br>';
                                    //<div id="more-'+item.id+'" style="display: none">' + longText + '</div><a id="link-'+item.id+'" href="javascript:showMore('+ item.id + ')">Klick mich</a>
                                  } else {
                                    offerDesc = '<p>' + item.beschreibung + '</p>';
                                  }*/
                                  offerDesc = '<p>' + shortText + '</p>';
                                }
                            } else {
                                offerDesc = '';
                            }
                            if (item.anmeldung_kostenlos) {
                                freeOffer = '<li class="for-free">Kostenloses Angebot</li>';
                            } else {
                                freeOffer = '';
                            }
                            offerFeeComment = '';
                            if (item.anmeldung_kosten) {
                                if (item.anmeldung_kosten.indexOf('<p') > -1) {
                                    offerFeeComment = '<div class="fee-comment">' + item.anmeldung_kosten + '</div>';
                                } else {
                                    offerFee = '<li>' + item.anmeldung_kosten + '</li>';
                                }
                            } else {
                                offerFee = '';
                            }
                            ageLi = '';
                            if (typeof item.alter != "undefined") {
                                if (item.alter.length > 0) {
                                    $(item.alter).each(function (i, age) {
                                        ageLi += '<li>' + age;
                                    });
                                }
                            }
                            if (item.angebot_form) {
                                offerForm = '<li>' + item.angebot_form + '</li>';
                            } else {
                                offerForm = '';
                            }
                            let angebotUrl = '/fhiangebot/details/id/' + item.id;
                            if (item.newlayoutpreview) {
                                angebotUrl = '/fhiangebot/details/id/' + item.id + '?newlayoutpreview=1';
                            }

                            let angebotOrt = '';
                            if (item.ort) {
                              angebotOrt = '<p class="location">' + item.ort + '</p>';
                            }

                            let angebotImage = '';
                            if (item.angebote_image) {
                              angebotImage = '<figure>' +
                                '<img src="' + imgBaseUrl + item.angebot_image + '">' +
                                '<figcaption>' + item.angebot_image_quelle + '</figcaption>' +
                                '</figure>';
                            }

                            var q = getUrlParameter('q');

                            if (q) {
                              var oneBox = $('<li class="border-box">' +
                                  '               <a target="_blank" id="link-box" href="' + (!isDevServer || 1 == 1 ? angebotUrl : '/angebote-detail.html') + '">' +
                                  '                   <div>' +
                                  '                       <h2>' + metaH2 + item.angebot_name + '</h2>' +
                                  '                       ' + offerDesc +
                                  '                       <p class="date-time">' + item.wann + '</p>' +
                                  '                       ' + angebotOrt + '' +
                                  '                       <ul class="tags">' + freeOffer + ageLi + offerForm + offerFee + '</ul>' +
                                  '                   </div>' +
                                  '                   <figure>' +
                                  '                       <img src="' + imgBaseUrl + item.angebot_image + '">' +
                                  '                       <figcaption>' + item.angebot_image_quelle + '</figcaption>' +
                                  '                   </figure>' +
                                  '               </a>' + offerFeeComment +
                                  '           </li>'
                                )
                              ;
                            } else {
                              var oneBox = $('<li class="border-box">' +
                                  '               <a id="link-box" href="' + (!isDevServer || 1 == 1 ? angebotUrl : '/angebote-detail.html') + '">' +
                                  '                   <div>' +
                                  '                       <h2>' + metaH2 + item.angebot_name + '</h2>' +
                                  '                       ' + offerDesc +
                                  '                       <p class="date-time">' + item.wann + '</p>' +
                                  '                       ' + angebotOrt + '' +
                                  '                       <ul class="tags">' + freeOffer + ageLi + offerForm + offerFee + '</ul>' +
                                  '                   </div>' +
                                  '                   <figure>' +
                                  '                       <img src="' + imgBaseUrl + item.angebot_image + '">' +
                                  '                       <figcaption>' + item.angebot_image_quelle + '</figcaption>' +
                                  '                   </figure>' +
                                  '               </a>' + offerFeeComment +
                                  '           </li>'
                                )
                              ;
                            }

                            $resultBox.append(oneBox);

                            setTimeout(function () {
                              if (parseInt(document.getElementById('number-of-offer').innerHTML, 10) > 20 && loadmoreState) {
                                $('.load-more').fadeIn();
                              }
                            }, 1500);
                            // return;
                            break;
                        case 'load-provider':

                            if (!isDevServer) {
                                if (item.newlayoutpreview) {
                                    angebotLink = '/' + item.url_name + '.suche?topic=undefined&age=undefined&anbieterid=' + item.id + '&newlayoutpreview=1';
                                } else {
                                    angebotLink = '/' + item.url_name + '.suche?topic=undefined&age=undefined&anbieterid=' + item.id;
                                }
                            } else {
                                angebotLink = '/angebote-detail.html';
                            }

                            providerDesc = '';
                            if (item.anbieter_beschreibung != null) {

                                providerDesc = '<p>' + item.anbieter_beschreibung + '</p>';
                                console.log(typeof  item.anbieter_beschreibung, item.anbieter_beschreibung, 'NULL')
                            }
                            providerDesc += '<p>' + item.anbieter_str + '<br>' + item.anbieter_plz + ' ' + item.anbieter_ort + '</p>'

                            var oneBox = $('<div class="border-box">' +
                                '               <a href="' + angebotLink + '">' +
                                '               <div>' +
                                '                   <h2><!--' + item.id + '. -->' + item.anbieter_name + '  </h2>' +
                                '                   ' + providerDesc +
                                '                   <p class="more">Zu den Angeboten</p>' +
                                '               </div>' +
                                '       <figure>' +
                                '            <img src="' + item.anbieter_image + '" alt="' + item.anbieter_name + '">' +
                                '        </figure>' +
                                '    </a></div>');
                            $resultBox.append(oneBox);

                            setTimeout(function () {
                              console.log(parseInt(document.getElementById('number-of-offer').innerHTML, 10));
                              if (parseInt(document.getElementById('number-of-offer').innerHTML, 10) > 20 && loadmoreState) {
                                $('.load-more').fadeIn();
                              }
                            }, 1500);

                            break;
                        default:
                            if (isDevServer) {
                                console.log('no task found: ' + loadTask);
                            }
                    }
                } catch (e) {
                    console.log(e)
                }

            });
        } else {
            displayErrorMessage('Keine Treffer<br><br>Zu Ihrem Suchbegriff konnte kein Ergebnis gefunden werden. Bitte wählen Sie einen anderen Suchbegriff oder verringern Sie gegebenenfalls bereits gesetzte Filter-Einschränkungen. Es werden Suchbegriffe aus dem Angebotstitel, dem Angebotstext oder ggf. durch den Anbieter im Angebot hinterlegte Schlagworte bei der Suche berücksichtigt.');
            $('.load-more').hide();
            loadmoreState = false;
        }
        hideLoader();

    };

    var $numberOfOffer = $('#number-of-offer');

    /**
     * Anzahl der ermittelten Suchergebinsse wird angezeigt
     * @param data
     */
    var setNumberOfOffer = function (data) {
        if ($numberOfOffer.length > 0) {
            $numberOfOffer.text('');
            if (typeof data.totalcount == 'number') {
                let selectedSubInputs = $('.filter-col input[name*=filter]:checked').not('#box-0').length;
                if (selectedSubInputs == 0) {
                    var $numberOfAllKategorie = $('#number-of-all-kategorie');
                    $numberOfAllKategorie.text('(' + data.totalcount + ')');
                }
                $numberOfOffer.text(data.totalcount);
                setTimeout(function () {
                    if (parseInt(document.getElementById('number-of-offer').innerHTML, 10) > 20 && loadmoreState) {
                        $('.load-more').fadeIn();
                    } else {
                        $('.load-more').hide();
                    }
                }, 1500);
            }
        }
    };


    /**
     * Fehlermeldung, falls AJAX mit Fehler oder leer
     * @param message
     */
    var displayErrorMessage = function (message) {
        var $resultBox = $('#result-box');
        $resultBox.text('');
        var oneBox = $('<p class="error">' + message + '</p>');
        $resultBox.append(oneBox);
    };


    var $loaderBb = $('#full-loader-bg');
    var $loader = $('#full-loader');

    /**
     * Lade-Symbol wir angezeigt
     */
    var showLoader = function () {
        $loader.removeClass('fadeOut');
        $loaderBb.removeClass('fadeOut');
    };

  $('#search').on('keyup', function () {
    var suchfeld = $('#search').val();
    var sucheResetLink = $('.reset-filter-suche');
    if (suchfeld.length > 0) {
      sucheResetLink.addClass('active')
    } else {
      sucheResetLink.removeClass('active')
    }
  });


    /**
     * Lade-Symbol wird ausbeblendte
     */
    var hideLoader = function () {
        setTimeout(function () {
            $loader.addClass('fadeOut');
            $loaderBb.addClass('fadeOut');
            // console.log('hideLoader')
        }, 100);
    };

    var footerPos = 0, footerTop = 0, offerBoxPos = 0, offerBoxTop = 0, latestTopPos = 0, funcBoxPos = 0, funcBoxTop = 0, detailsBoxHeight = 0;

    if ($('.offer-compact').length > 0) {
        offerBoxPos = $('.offer-compact').position();
        offerBoxTop = offerBoxPos.top;
    }
    if ($('#functions_bottom').length > 0) {
        funcBoxPos = $('#functions_bottom').position();
        funcBoxTop = funcBoxPos.top;
    }

    $('.search').on('keyup', function () {
      var suchfeld = $('.search').val();
      if (suchfeld.length > 0) {
        $resetLink.addClass('active')
      } else {
        $resetLink.removeClass('active')
      }
    });

    /**
     * Nach-oben LInk anzeigen bzw. ausblenden und an die richtige Stelle positionieren
     * @param scrollTop
     * @param winH
     * @param footerH
     * @param $toTop
     */
    var showHideToTop = function (scrollTop, winH, footerH, $toTop) {
        footerPos = $('footer').position();
        footerTop = footerPos.top;
        if (scrollTop > winH) {
            $toTop.fadeIn();
        } else {
            $toTop.fadeOut();
        }
        if (scrollTop + winH > footerTop) {
            $toTop.css('transform', 'translate(0,-' + (scrollTop + winH - footerTop) + 'px)')
        } else {
            $toTop.css('transform', 'translate(0,0')
        }
    };

    /**
     * EN-Datum wird in DE-Datum konfertier
     * @param datum
     * @returns {string}
     */
    var convertDateToDe = function (datum) {
        if (!datum) {
            return '';
        }
        var parts = datum.split("-");
        return parts[2] + '.' + parts[1] + '.' + parts[0];
    };

  var loadSearchResults = function () {
    let url = null;
    url = '/fhiangebot/searchredesign';

    var postData = { 'einrichtungId' : $('#einrichtungId').val() };
    var q = { 'q' : $('#search').val() };

    var mergedParams = $.extend({}, postData, q);

    $.ajax({
      dataType: "json",
      url: url,
      async: true,
      data: mergedParams,
      success: function (data) {
        let result = document.getElementById('result');
        result.innerHTML = '';

        let list = '';

        $.each(data.data, function (idx, value) {
          // var $option = '';

          $dataListAutoComplete.append('<option value="'+value.angebot_name+ ' [' + value.id +']" id="opt-search-' + (1 + idx) + '" role="option">' + value.beschreibung + '</option>');
        });

        $dataListAutoComplete.show();

        $('#search').attr('aria-expanded', 'true');
      }
    });
  }

  /**
     * Eingabe PLZ wird  für Auto-Suggest geladen
     */
    var loadPLZResults = function () {
      let url = null;
      url = '/fhiangebot/getplz';

      var postData = { 'plz': $('#wo_plz').val() };

      $.getJSON(url, postData, function (data) {
        $dataListPlz.html('');

        $.each(data.data, function (idx, value) {
          // var $option = '';
          var $option = $('<option id="opt-plz-' + (1 + idx) + '" role="option">' + value.plz + '</option>');
          $dataListPlz.append($option)
        });

        $('#wo_plz').attr('aria-expanded', 'true');
        //$dataListPlz.show();
      });
    };


    var latestCombo = {};
    var optionCounter = 0;


  $('#q').on('input', function(){
    var result = $(this).val();

    /*
    let pattern = '\\[(.*?)\\]';

    let test = result.match(pattern);
    console.log(test);
     */
    window.location.href = 'your url' + page;
  });


    // https://www.guterstart.nrw.de/koeln.suche?stadtteil=152


    /*
 *   This content is licensed according to the W3C Software License at
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 */

  'use strict';

  class ComboboxAutocomplete {

    constructor(comboboxNode, buttonNode, listboxNode) {
      this.comboboxNode = comboboxNode;
      this.buttonNode = buttonNode;
      this.listboxNode = listboxNode;

      this.comboboxHasVisualFocus = false;
      this.listboxHasVisualFocus = false;

      this.hasHover = false;

      this.isNone = false;
      this.isList = false;
      this.isBoth = false;

      this.allOptions = [];

      this.option = null;
      this.firstOption = null;
      this.lastOption = null;

      this.filteredOptions = [];
      this.filter = '';

      var autocomplete = this.comboboxNode.getAttribute('aria-autocomplete');

      // alert('INIT');

      if (typeof autocomplete === 'string') {
        autocomplete = autocomplete.toLowerCase();
        this.isNone = autocomplete === 'none';
        this.isList = autocomplete === 'list';
        this.isBoth = autocomplete === 'both';
      } else {
        // default value of autocomplete
        this.isNone = true;
      }

      if (isDevServer) {
        //   console.log('this.comboboxNode', this.comboboxNode);
      }

      this.comboboxNode.addEventListener(
        'keydown',
        this.onComboboxKeyDown.bind(this)
      );
      this.comboboxNode.addEventListener(
        'keyup',
        this.onComboboxKeyUp.bind(this)
      );
      this.comboboxNode.addEventListener(
        'click',
        this.onComboboxClick.bind(this)
      );
      this.comboboxNode.addEventListener(
        'focus',
        this.onComboboxFocus.bind(this)
      );
      this.comboboxNode.addEventListener('blur', this.onComboboxBlur.bind(this));

      document.body.addEventListener(
        'pointerup',
        this.onBackgroundPointerUp.bind(this),
        true
      );

      // initialize pop up menu

      this.listboxNode.addEventListener(
        'pointerover',
        this.onListboxPointerover.bind(this)
      );
      this.listboxNode.addEventListener(
        'pointerout',
        this.onListboxPointerout.bind(this)
      );

      // Traverse the element children of domNode: configure each with
      // option role behavior and store reference in.options array.
      var nodes = this.listboxNode.getElementsByTagName('LI');

      for (var i = 0; i < nodes.length; i++) {
        var node = nodes[i];
        this.allOptions.push(node);

        node.addEventListener('click', this.onOptionClick.bind(this));
        node.addEventListener('pointerover', this.onOptionPointerover.bind(this));
        node.addEventListener('pointerout', this.onOptionPointerout.bind(this));
      }

      this.filterOptions();

      this.destroy = function () {
      };

      // Open Button

      var button = this.comboboxNode.nextElementSibling;

      if (button && button.tagName === 'BUTTON') {
        button.addEventListener('click', this.onButtonClick.bind(this));
      }

      //   alert('INIT COMPE')
    }


    getLowercaseContent(node) {
      return node.textContent.toLowerCase();
    }

    isOptionInView(option) {
      var bounding = option.getBoundingClientRect();
      return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    setActiveDescendant(option) {
      if (option && this.listboxHasVisualFocus) {
        this.comboboxNode.setAttribute('aria-activedescendant', option.id);
        if (!this.isOptionInView(option)) {
          option.scrollIntoView({behavior: 'smooth', block: 'nearest'});
        }
      } else {
        this.comboboxNode.setAttribute('aria-activedescendant', '');
      }
    }

    setValue(value) {
      this.filter = value;
      this.comboboxNode.value = this.filter;
      this.comboboxNode.setSelectionRange(this.filter.length, this.filter.length);
      this.filterOptions();
    }

    setOption(option, flag) {
      if (typeof flag !== 'boolean') {
        flag = false;
      }

      if (option) {
        this.option = option;
        this.setCurrentOptionStyle(this.option);
        this.setActiveDescendant(this.option);

        if (this.isBoth) {
          this.comboboxNode.value = this.option.textContent;
          if (flag) {
            this.comboboxNode.setSelectionRange(
              this.option.textContent.length,
              this.option.textContent.length
            );
          } else {
            this.comboboxNode.setSelectionRange(
              this.filter.length,
              this.option.textContent.length
            );
          }
        }
      }
    }

    setVisualFocusCombobox() {
      this.listboxNode.classList.remove('focus');
      this.comboboxNode.parentNode.classList.add('focus'); // set the focus class to the parent for easier styling
      this.comboboxHasVisualFocus = true;
      this.listboxHasVisualFocus = false;
      this.setActiveDescendant(false);
    }

    setVisualFocusListbox() {
      this.comboboxNode.parentNode.classList.remove('focus');
      this.comboboxHasVisualFocus = false;
      this.listboxHasVisualFocus = true;
      this.listboxNode.classList.add('focus');
      this.setActiveDescendant(this.option);
    }

    removeVisualFocusAll() {
      this.comboboxNode.parentNode.classList.remove('focus');
      this.comboboxHasVisualFocus = false;
      this.listboxHasVisualFocus = false;
      this.listboxNode.classList.remove('focus');
      this.option = null;
      this.setActiveDescendant(false);
    }

    // ComboboxAutocomplete Events

    filterOptions() {
      // do not filter any options if autocomplete is none
      if (this.isNone) {
        this.filter = '';
      }

      var option = null;
      var currentOption = this.option;
      var filter = this.filter.toLowerCase();

      this.filteredOptions = [];
      this.listboxNode.innerHTML = '';

      for (var i = 0; i < this.allOptions.length; i++) {
        option = this.allOptions[i];
        if (
          filter.length === 0 ||
          this.getLowercaseContent(option).indexOf(filter) === 0
        ) {
          this.filteredOptions.push(option);
          this.listboxNode.appendChild(option);
        }
      }

      // Use populated options array to initialize firstOption and lastOption.

      var numItems = this.filteredOptions.length;

      if (isDevServer) {
        //    console.log('numItems', numItems)

        //  console.log('this.allOptions', this.allOptions)
      }

      if (numItems > 0) {
        this.firstOption = this.filteredOptions[0];
        this.lastOption = this.filteredOptions[numItems - 1];

        if (currentOption && this.filteredOptions.indexOf(currentOption) >= 0) {
          option = currentOption;
        } else {
          option = this.firstOption;
        }
      } else {
        this.firstOption = null;
        option = null;
        this.lastOption = null;
      }

      return option;
    }

    setCurrentOptionStyle(option) {


      if (isDevServer) {
        console.log('option', option);
      }

      for (var i = 0; i < this.filteredOptions.length; i++) {
        var opt = this.filteredOptions[i];
        if (opt === option) {
          opt.setAttribute('aria-selected', 'true');
          if (
            this.listboxNode.scrollTop + this.listboxNode.offsetHeight <
            opt.offsetTop + opt.offsetHeight
          ) {
            this.listboxNode.scrollTop =
              opt.offsetTop + opt.offsetHeight - this.listboxNode.offsetHeight;
          } else if (this.listboxNode.scrollTop > opt.offsetTop + 2) {
            this.listboxNode.scrollTop = opt.offsetTop;
          }
        } else {
          opt.removeAttribute('aria-selected');
        }
      }
    }

    getPreviousOption(currentOption) {
      if (currentOption !== this.firstOption) {
        var index = this.filteredOptions.indexOf(currentOption);
        return this.filteredOptions[index - 1];
      }
      return this.lastOption;
    }

    getNextOption(currentOption) {
      if (currentOption !== this.lastOption) {
        var index = this.filteredOptions.indexOf(currentOption);
        return this.filteredOptions[index + 1];
      }
      return this.firstOption;
    }

    /* MENU DISPLAY METHODS */

    doesOptionHaveFocus() {
      return this.comboboxNode.getAttribute('aria-activedescendant') !== '';
    }

    isOpen() {
      return this.listboxNode.style.display === 'block';
    }

    isClosed() {
      return this.listboxNode.style.display !== 'block';
    }

    hasOptions() {
      return this.filteredOptions.length;
    }

    open() {
      this.listboxNode.style.display = 'block';
      this.comboboxNode.setAttribute('aria-expanded', 'true');
      // this.buttonNode.setAttribute('aria-expanded', 'true');
    }

    close(force) {
      if (typeof force !== 'boolean') {
        force = false;
      }

      if (
        force ||
        (!this.comboboxHasVisualFocus &&
          !this.listboxHasVisualFocus &&
          !this.hasHover)
      ) {
        this.setCurrentOptionStyle(false);
        this.listboxNode.style.display = 'none';
        this.comboboxNode.setAttribute('aria-expanded', 'false');
        // this.buttonNode.setAttribute('aria-expanded', 'false');
        this.setActiveDescendant(false);
        this.comboboxNode.parentNode.classList.add('focus');
      }
    }

    /* combobox Events */

    onComboboxKeyDown(event) {
      var flag = false,
        altKey = event.altKey;

      if (event.ctrlKey || event.shiftKey) {
        return;
      }

      switch (event.key) {
        case 'Enter':
          if (this.listboxHasVisualFocus) {
            this.setValue(this.option.textContent);
          }
          this.close(true);
          this.setVisualFocusCombobox();
          flag = true;
          break;

        case 'Down':
        case 'ArrowDown':
          if (this.filteredOptions.length > 0) {
            if (altKey) {
              this.open();
            } else {
              this.open();
              if (
                this.listboxHasVisualFocus ||
                (this.isBoth && this.filteredOptions.length > 1)
              ) {
                this.setOption(this.getNextOption(this.option), true);
                this.setVisualFocusListbox();
              } else {
                this.setOption(this.firstOption, true);
                this.setVisualFocusListbox();
              }
            }
          }
          flag = true;
          break;

        case 'Up':
        case 'ArrowUp':
          if (this.hasOptions()) {
            if (this.listboxHasVisualFocus) {
              this.setOption(this.getPreviousOption(this.option), true);
            } else {
              this.open();
              if (!altKey) {
                this.setOption(this.lastOption, true);
                this.setVisualFocusListbox();
              }
            }
          }
          flag = true;
          break;

        case 'Esc':
        case 'Escape':
          if (this.isOpen()) {
            this.close(true);
            this.filter = this.comboboxNode.value;
            this.filterOptions();
            this.setVisualFocusCombobox();
          } else {
            this.setValue('');
            this.comboboxNode.value = '';
          }
          this.option = null;
          flag = true;
          break;

        case 'Tab':
          this.close(true);
          if (this.listboxHasVisualFocus) {
            if (this.option) {
              this.setValue(this.option.textContent);
            }
          }
          break;

        case 'Home':
          this.comboboxNode.setSelectionRange(0, 0);
          flag = true;
          break;

        case 'End':
          var length = this.comboboxNode.value.length;
          this.comboboxNode.setSelectionRange(length, length);
          flag = true;
          break;

        default:
          break;
      }

      if (flag) {
        event.stopPropagation();
        event.preventDefault();
      }
    }

    isPrintableCharacter(str) {
      return str.length === 1 && str.match(/\S| /);
    }

    onComboboxKeyUp(event) {
      var flag = false,
        option = null,
        char = event.key;

      if (this.isPrintableCharacter(char)) {
        this.filter += char;
      }

      // this is for the case when a selection in the textbox has been deleted
      if (this.comboboxNode.value.length < this.filter.length) {
        this.filter = this.comboboxNode.value;
        this.option = null;
        this.filterOptions();
      }

      if (event.key === 'Escape' || event.key === 'Esc') {
        return;
      }

      switch (event.key) {
        case 'Backspace':
          this.setVisualFocusCombobox();
          this.setCurrentOptionStyle(false);
          this.filter = this.comboboxNode.value;
          this.option = null;
          this.filterOptions();
          flag = true;
          break;

        case 'Left':
        case 'ArrowLeft':
        case 'Right':
        case 'ArrowRight':
        case 'Home':
        case 'End':
          if (this.isBoth) {
            this.filter = this.comboboxNode.value;
          } else {
            this.option = null;
            this.setCurrentOptionStyle(false);
          }
          this.setVisualFocusCombobox();
          flag = true;
          break;

        default:
          if (this.isPrintableCharacter(char)) {
            this.setVisualFocusCombobox();
            this.setCurrentOptionStyle(false);
            flag = true;

            if (this.isList || this.isBoth) {
              option = this.filterOptions();
              if (option) {
                if (this.isClosed() && this.comboboxNode.value.length) {
                  this.open();
                }

                if (
                  this.getLowercaseContent(option).indexOf(
                    this.comboboxNode.value.toLowerCase()
                  ) === 0
                ) {
                  this.option = option;
                  if (this.isBoth || this.listboxHasVisualFocus) {
                    this.setCurrentOptionStyle(option);
                    if (this.isBoth) {
                      this.setOption(option);
                    }
                  }
                } else {
                  this.option = null;
                  this.setCurrentOptionStyle(false);
                }
              } else {
                this.close();
                this.option = null;
                this.setActiveDescendant(false);
              }
            } else if (this.comboboxNode.value.length) {
              this.open();
            }
          }

          break;
      }

      if (flag) {
        event.stopPropagation();
        event.preventDefault();
      }
    }

    onComboboxClick() {
      if (this.isOpen()) {
        this.close(true);
      } else {
        this.open();
      }
    }

    onComboboxFocus() {
      this.filter = this.comboboxNode.value;
      this.filterOptions();
      this.setVisualFocusCombobox();
      this.option = null;
      this.setCurrentOptionStyle(null);
    }

    onComboboxBlur() {
      this.removeVisualFocusAll();
    }

    onBackgroundPointerUp(event) {
      if (
        !this.comboboxNode.contains(event.target) &&
        !this.listboxNode.contains(event.target) &&
        !this.buttonNode.contains(event.target)
      ) {
        this.comboboxHasVisualFocus = false;
        this.setCurrentOptionStyle(null);
        this.removeVisualFocusAll();
        setTimeout(this.close.bind(this, true), 300);
      }
    }

    onButtonClick() {
      if (this.isOpen()) {
        this.close(true);
      } else {
        this.open();
      }
      this.comboboxNode.focus();
      this.setVisualFocusCombobox();
    }

    /* Listbox Events */

    onListboxPointerover() {
      this.hasHover = true;
    }

    onListboxPointerout() {
      this.hasHover = false;
      setTimeout(this.close.bind(this, false), 300);
    }

    // Listbox Option Events

    onOptionClick(event) {
      this.comboboxNode.value = event.target.textContent;
      this.close(true);
    }

    onOptionPointerover() {
      this.hasHover = true;
      this.open();
    }

    onOptionPointerout() {
      this.hasHover = false;
      setTimeout(this.close.bind(this, false), 300);
    }
  }

  // Initialize comboboxes

  // window.addEventListener('load', function () {
  //     var comboboxes = document.querySelectorAll('.combobox-list');
  //
  //     for (var i = 0; i < comboboxes.length; i++) {
  //         var combobox = comboboxes[i];
  //         var comboboxNode = combobox.querySelector('input');
  //         var buttonNode = combobox.querySelector('button');
  //         var listboxNode = combobox.querySelector('[role="listbox"]');
  //         new ComboboxAutocomplete(comboboxNode, buttonNode, listboxNode);
  //     }
  // });
} catch (err) {
    if (isDevServer) {
        console.log('err: ', err);
    }
    alert(err.message)
}

